import {_request} from "../functions";

class api {
  constructor() {
    if (!this.setVars()) return false
    this.recaptcha = document.body.querySelector('[data-callback="submit"]')
    if(this.recaptcha) {
      this.recaptcha.disabled = true
      this.is_valid = true
      addJS('https://www.google.com/recaptcha/api.js?render=6LcNrxEoAAAAANxCmSYcIt8nGuEv03bDhnR9NPzo', {
        id: '_captcha', async: true, defer: true, cb: () => {
          grecaptcha.ready(() => {
            grecaptcha.execute('6LcNrxEoAAAAANxCmSYcIt8nGuEv03bDhnR9NPzo', {action: 'submit'}).then((response) => {
              _request('api/recaptcha', JSON.stringify({
                _token: csrf,
                response: response
              }), {
                cb: (xhr) => {
                  xhr.json().then(json => {
                    switch (json.success) {
                      case true:
                        this.is_valid = true
                        break;
                    }
                  })
                  this.setEvents()
                }
              })

            })
          })
        }
      })
    } else this.setEvents()
  }

  setVars() {
    this.forms = document.body.querySelectorAll('form[data-api]')
    if (!this.forms.length) return false

    this.paypal = document.getElementById('paypal-button-container')
    return true;
  }

  setEvents() {
    let data = {}, autoSubmit, paymentType;
    if(this.recaptcha) this.recaptcha.disabled = false
    each(this.forms, (key, val) => {
      val.addEventListener('submit', (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!this.is_valid && this.recaptcha) return this.captchaError()
        if (!val.length) return
        if (!val.dataset.api) return

        if (this.validateForm(val)) return this.modal({'status': false, 'msg': 'Uzupełnij wymagane pola'})
        if (!this.orderData._token) this.orderData._token = csrf

        if (!this.is_valid && this.recaptcha) return this.captchaError()

        this.orderData.paymentType = this.paymentType || false
        this.paymentType = false

        _request(val.dataset.api, JSON.stringify(this.orderData), {
          cb: (xhr) => {
            xhr.json().then(response => {
              val.reset();
              response.msg ? this.modal(response, () => {
                this.redirect(response)
              }) : this.redirect(response)
            })
          }
        });
      })

      paymentType = val.querySelector('[data-payment]');
      paymentType && paymentType.addEventListener('click', (e) => {
        e.preventDefault()
        this.paymentType = e.target.dataset.payment || false
        e.target.previousElementSibling.click()
      })

      autoSubmit = val.querySelectorAll('[data-submit="auto"]')
      autoSubmit.length && each(autoSubmit, (i, field) => {
        field.addEventListener('change', () => {
          try {
            val[val.length - 1].click()
          } catch (e) {
            console.error('invalid submit button')
          }
        })
      })
    })
    this.sumOrder()

    this.paypal && addJS('https://www.paypal.com/sdk/js?client-id=AfLd8zNE3QlSFs14Yuj1PiGtPjqUrM-RTZt40TK366pwMvfIC2Hw8QHCWalJcULXgBBiTiJeHWMm_eum&components=buttons,funding-eligibility&enable-funding=blik&currency=PLN&locale=pl_PL&commit=false', {
      cb: () => {
        this.loadPayPalSDK()
      }
    })
  }

  sumOrder() {
    this.quantity = document.body.querySelectorAll('input[name="quantity"]')
    this.amount = document.body.querySelectorAll('input[name="totalAmount"]')
    this.quantity.length && each(this.quantity, (key, val) => {
      val.addEventListener('keyup', () => {
        this.amount[key].value = parseFloat(parseFloat(val.dataset.price) * parseInt(val.value)).toFixed(2)
      })
    })
  }

  resetReCaptcha(valid = false) {
    this.is_valid = valid;
    grecaptcha && grecaptcha.reset()
  }

  captchaError(callback) {
    return this.modal({msg: 'Nie udało się zweryfikować reCaptcha,<br>Spróbuj ponownie.'}, {
      cb: () => {
        if (typeof callback === 'function') callback()
      }
    })
  }

  redirect(response) {
    if (response.reload) return this.reload()
    if (!response.redirectUri) return;
    window.location.href = response.redirectUri
  }

  reload() {
    window.location.href = window.location.href
  }

  modal(response = '', cb) {
    let modal = render(document.body, `<div id="modal" class="row modal h100 fix top0 left0 flex align-center justify-center" style="z-index:900">
  <div class="shad ${!response.status ? 'error' : ''}" style="max-width:470px;width:100%;padding:2em;border-radius:15px;background-color:#fff">
    <h6 style="font-size:1em;font-weight:300">${response.msg}</h6>
    <button class="btn btn--sm mt30">OK</button>
  </div>
</div>`)
    let btn = modal.getElementsByTagName('button')[0]
    btn && btn.addEventListener('click', () => {
      del(modal)
      if (typeof cb === 'function') cb()
    })
    return modal
  }

  validateForm(form) {
    this.orderData = {}
    let inputs = form.querySelectorAll('input,select,textarea'), minLength = 0, error = []
    if (!inputs.length) return;

    each(inputs, (key, val) => {
      minLength = parseInt(val.dataset.min) || 0
      switch (val.type) {
        case 'checkbox':
          if (val.dataset.req === '1' && val.checked) {
            val.parentNode.classList.remove('error')
          } else if (val.dataset.req === '1' && !val.checked) {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          this.orderData[val.name] = val.checked
          break;
        case 'email':
          if (is_email(val.value)) {
            val.parentNode.classList.remove('error')
            this.orderData[val.name] = val.value.toLowerCase()
          } else {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          break;
        default:
          if (val.value.length >= minLength) {
            val.parentNode.classList.remove('error')
            this.orderData[val.name] = val.value
          } else {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
      }
    })
    return error.length
  }

  loadPayPalSDK() {
    paypal.Buttons({
      createOrder: (data, actions) => {
        if (this.validateForm(this.forms[0])) return this.modal({'status': false, 'msg': 'Uzupełnij wymagane pola'})

        let obj = {
          "purchase_units": [{
            "custom_id": 0,
            "description": "",
            "amount": {
              "currency_code": "PLN",
              "value": "0",
              "breakdown":
                {
                  "item_total":
                    {
                      "currency_code": "PLN",
                      "value": "0"
                    }
                }
            },
            "items":
              [
                {
                  "name": "Podaruj obiad",
                  "description": "Dotacja",
                  "unit_amount": {
                    "currency_code": 'PLN',
                    "value": '0'
                  },
                  "quantity": "1",
                  "category": "DONATION"
                },
              ]
          }]
        }
        obj.purchase_units[0].custom_id = parseInt(this.orderData.prodId)
        obj.purchase_units[0].description = this.orderData.description
        obj.purchase_units[0].amount.value = parseFloat(this.orderData.quantity) * parseFloat(this.orderData.unitPrice)
        obj.purchase_units[0].amount.breakdown.item_total.value = parseFloat(this.orderData.quantity) * parseFloat(this.orderData.unitPrice)
        obj.purchase_units[0].items[0].name = this.orderData.productName
        obj.purchase_units[0].items[0].description = this.orderData.description
        obj.purchase_units[0].items[0].unit_amount.value = this.orderData.unitPrice
        obj.purchase_units[0].items[0].quantity = this.orderData.quantity

        return actions.order.create(obj)
      },
      onApprove: (data, actions) => {
        return actions.order.capture().then((orderData) => {
          let obj = Object.assign({
            'paypalOrderCheck': 1,
            'paypalOrderId': orderData.id
          }, this.orderData);
          fetch('https://zawieszonyobiad.org.pl/api/order', {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(obj)
          })
            .then((response) => response.json())
            .then((result) => {
              return this.modal(result)
            })
            .catch(error => console.log(error));
        });
      }
    }).render('#paypal-button-container');
  }
}

export default api
